/*body {
  background: #F1F3FA;
}*/

.content{
    margin-top: 55px;
}

.user-row {
    margin-bottom: 14px;
}

.user-row:last-child {
    margin-bottom: 0;
}

.dropdown-user {
    margin: 13px 0;
    padding: 5px;
    height: 100%;
}

.dropdown-user:hover {
    cursor: pointer;
}

.table-user-information > tbody > tr {
    border-top: 1px solid rgb(221, 221, 221);
}

.table-user-information > tbody > tr:first-child {
    border-top: 0;
}


.table-user-information > tbody > tr > td {
    border-top: 0;
}
.toppad{
    margin-top:20px;
}
.btn-loading-overlay{
    position: absolute;
    background: rgba(255,255,255,0.4);
    width: 100%;
    height: 110%;
    z-index: 1;
}
