.review-wrap {
    background-color: #dcdcdc;
    margin-bottom: 50px;
}

.review-home .review-header h2 {
    margin-bottom: 0px;
    font-weight: bold;
}

.review-home .review-header p {
    font-size: 24px;
    font-weight: bold;
}

.review-home .review-header {
    text-align: center;
    color: #111;
}

.review-home .review-card {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(50, 50, 50, 0.27);
    margin-bottom: 20px;
}

.review-home .review-cards {
    padding: 30px 15px;
}

.review-home .rating {
    text-align: center;
    font-size: 12px;
    padding: 20px;
}

.review-home .rating .red {
    color: #ff5a60;
}

.review-home .title {
    font-size: 18px;
}

.review-home .review-content {
    font-size: 16px;
    line-height: 1.5;
}

.review-home .fa-user {
    font-size: 20px;
    color: #aaa;
}

.review-home .review-author span {
    font-weight: bold;
    color: #aaa;
    margin-left: 5px;
}

.review-home .carousel-control {
    background-image: none !important;
}

.review-home .carousel-control .glyphicon-chevron-left {
    left: 0 !important;
}

.review-home .carousel-control .glyphicon-chevron-right {
    right: 0 !important;
}

.review-home .carousel-indicators {
    bottom: 5px !important;
}

.review-doctor {
    border-top: 1px solid #c0bfbf;
    margin-top: 10px;
    padding-top: 10px;
}

.review-doctor .doctor-name p {
    margin: 0px;
}

.review-doctor .doctor-name {
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px;
}

.review-doctor .doctor-name p {
    margin: 0;
}

.review-doctor .btn-primary {
    position: relative;
    left: 40%;
}

.review-home h5 {
    font-size: 18px;
    color: #95b3ed;
    font-weight: bold;
}

span.btn.btn-default.form-control.ui-select-toggle:hover {
    background-color: #fff;
}

span.btn.btn-default.form-control.ui-select-toggle {
    text-overflow: ellipsis;
}

.pac-container:after {
    background-image: none !important;
    height: 0px;
}

@media (max-width: 767.98px) {
    .search-mobile-modal .modal-dialog {
        margin: auto;
        height: 100%;
        width: 100%;
    }
    .search-mobile-modal .modal-content {
        height: 100%;
    }
}

@media (min-width: 576px) {
    .search-mobile-modal .modal-dialog {
        margin: auto;
        height: 100%;
        width: 100%;
    }
    .search-mobile-modal .modal-content {
        height: 100%;
    }
}
ul#search_desktop{
    width:100%;
    position:absolute;
    z-index:1;
    max-height: 300px;
    overflow: auto;
}
ul#search_desktop li.list-group-item {
    padding: 6px 12px;
    border: 1px solid #efefef;
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
input.form-control.input-lg{
    /* border-radius: 0; */
    /* font-size: inherit; */
}
.form-control.input-lg.free-search-box {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}
.form-control.input-lg.location-search-box {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.slogan2 h1{
    font-size: 3.5em;
}
span.btn.btn-default.form-control.ui-select-toggle {
    width: 100%;
}
input.form-control.ui-select-search.ng-pristine.ng-valid.ng-touched {
    width: 100%;
}