.py-10 {padding:10px 0;}
.px-10 {padding:0 10px;}
.mt-15 { margin-top: 15px;}
.pb-20 {padding-bottom: 20px;}
.m-0 { margin: 0; }
.profile-title {
    font-weight: bold;
    font-size: 18px;
    margin: 15px 0;
    color: #000;
}
.profile-title-color { color: #5b9bd1; }
.profile-feature-color { color: #ffffff; }
.profile-supper-color { color: #aeaeae; }
.profile-background { background-color: #f9f9f9; }
.profile-appointment-background { background-color: #9EA2AA; }
.profile-favourite-background { background-color: #76C179; }
.profile-point-background { background-color: #849FD1; }
.profile-table-background { background-color: #E7E8EA; }
.profile-image { padding: 20px 0;}
a.profile-link {
  color: white;
}
a.profile-history-link{
  position:relative;
  display: block;
  text-decoration: none;
}
.pointer { cursor: pointer;}

.img-profile { height: 140px; width: 140px; }
/*modify img circle*/
.img-profile-circle  { height: 140px; width: 140px;border-radius:100px;}
.font-fav-box { font-size: 13px;color :black; }
.img-fix-fav { width: 100px }

.icon-menu {
  position: relative;
  display: block;
  background: url('../images/icon/menu_sprites.png');
  color: #aeaeae;
  margin: 0 auto;
}
.icon-menu:hover, .icon-menu.selected { color: #5b9bd1; }
.icon-menu.overview { background-position: 0 0; width: 55px; height: 55px;}
.icon-menu.overview::after { position:absolute; content: "Overview"; bottom: -25px; width:70px;}
.icon-menu.favorite { background-position: 55px 0; width: 55px; height: 55px;}
.icon-menu.favorite::after { position:absolute; content: "Favourite"; bottom: -25px; left: 2px; width:70px;}
.icon-menu.appointment { background-position: 110px 0; width: 55px; height: 55px;}
.icon-menu.appointment::after { position:absolute; content: "Appointment"; bottom: -25px; left: -10px; width:70px;}
.icon-menu.profile { background-position: 165px 0; width: 55px; height: 55px;}
.icon-menu.profile::after { position:absolute; content: "My Profile"; bottom: -25px; width:70px; }
.icon-menu.overview:hover, .icon-menu.overview.selected { background-position: 0 55px; width: 55px; height: 55px;}
.icon-menu.favorite:hover, .icon-menu.favorite.selected { background-position: 55px 55px; width: 55px; height: 55px;}
.icon-menu.appointment:hover, .icon-menu.appointment.selected { background-position: 110px 55px; width: 55px; height: 55px;}
.icon-menu.profile:hover, .icon-menu.profile.selected { background-position: 165px 55px; width: 55px; height: 55px;}
.profile-container{
  margin-top: 70px;
}

/* Profile sidebar */
.profile-sidebar {
  padding: 20px 0 10px 0;
  background: #fff;
}

.profile-userpic img {
  float: none;
  margin: 0 auto;
  width: 50%;
  height: 50%;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}

.profile-usertitle {
  text-align: center;
  margin-top: 20px;
}

.profile-usertitle-name {
  color: #5a7391;
  font-weight: 600;
  margin-bottom: 7px;
}

.profile-usertitle-job {
  text-transform: uppercase;
  color: #5b9bd1;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.profile-userbuttons {
  text-align: center;
  margin-top: 10px;
}

.profile-userbuttons .btn {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  padding: 6px 15px;
  margin-right: 5px;
}

.profile-userbuttons .btn:last-child {
  margin-right: 0px;
}
    
.profile-usermenu {
  margin-top: 30px;
}

.profile-usermenu ul li {
  border-bottom: 1px solid #f0f4f7;
}

.profile-usermenu ul li:last-child {
  border-bottom: none;
}

.profile-usermenu ul li a {
  color: #93a3b5;
  font-weight: 400;
}

.profile-usermenu ul li a i {
  margin-right: 8px;
  font-size: 14px;
}

.profile-usermenu ul li a:hover {
  background-color: #fafcfd;
  color: #5b9bd1;
}

.profile-usermenu ul li.active {
  border-bottom: none;
}

.profile-usermenu ul li.active a {
  color: #5b9bd1;
  background-color: #f6f9fb;
  border-left: 2px solid #5b9bd1;
  margin-left: -2px;
}

/* Profile Content */
.profile-content {
  /*padding: 20px;*/
  background: #fff;
  min-height: 460px;
}

/* Profile Add Icon Edit*/
.profile-icon-edit {
  margin-left: 70%;
}


figcaption.ratings
{
    margin-top:20px;
    }
figcaption.ratings a
{
    color:#f1c40f;
    font-size:11px;
    }
figcaption.ratings a:hover
{
    color:#f39c12;
    text-decoration:none;
}
.divider 
{
    border-top:1px solid rgba(0,0,0,0.1);
    margin: 15px 0;
}
.divider-no-margin
{
  border-top: 1px dotted rgba(0,0,0,0.1);
}
.border-line { 
  border: 1px solid #ccc; 
}
.emphasis 
{
    border-top: 4px solid transparent;
    }
.emphasis:hover 
{
    border-top: 4px solid #1abc9c;
    }
.emphasis h2
{
    margin-bottom:0;
    }
span.tags 
{
    background: #1abc9c;
    border-radius: 2px;
    color: #f5f5f5;
    font-weight: bold;
    padding: 2px 4px;
    }
/*.dropdown-menu 
{
    background-color: #34495e;    
    box-shadow: none;
    -webkit-box-shadow: none;
    width: 250px;
    margin-left: -125px;
    left: 50%;
    }
.dropdown-menu .divider 
{
    background:none;    
    }
.dropdown-menu>li>a
{
    color:#f5f5f5;
    }
.dropup .dropdown-menu 
{
    margin-bottom:10px;
    }
.dropup .dropdown-menu:before 
{
    content: "";
    border-top: 10px solid #34495e;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
    z-index: 10;
    }*/

/*File Upload */
.fileUpload {
    position: relative;
    overflow: hidden;
    margin: 10px;
}
.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

@media (max-width: 767px) {
    .xs-no-padding {
      padding:0;
      margin:0;
    }

    .xs-mt-15 {
      margin-top: 15px;
    }
}

/*# sourceMappingURL=profile.css.map */