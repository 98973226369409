body{
	/*background: #f9f9f9;*/
	background: #ffffff;
}
.content{
	margin-bottom: 30px;
	margin-top: 50px;
}
.detail{
	font-size: 16px;
    /*padding: 0;*/
    letter-spacing: 0.5px;
    color: #333;
    min-height: 700px;
}
.detail-tab{
	margin-top: 20px;
}
.doctor-short-detail{
	/*box-shadow: 0px 1px 5px rgba(50, 50, 50, 0.21);*/
	padding: 20px;
}
.address{
	font-size:16px;
}
.map{
	padding: 0px;
    min-height:250px;
}
.appointment{
    /*border-left: 1px solid #CECECD;*/
    /*border-right: 1px solid #CECECD;*/
}
.appointment h2{
	margin-top: 10px;
}
.appointment-item{
	padding: 0px;
	margin: 30px 0px;
	background-color: #ffffff;
}
.appintment-hospital{
	padding: 0px;
}
.appointment-table{
	padding: 0 20px;
	background: #fff;
}
.appointment-mobile-wrap{
	padding: 0px;
	margin: 15px 0;
}
.appointment-mobile{
	margin-top: 0px;
    margin-bottom: 0px;
}
.appointment-mobile .header{
	background-color: #FFFFFF;
    color: #859DE2;
    /*font-size: 24px;*/
    padding-top: 0px;
    padding-bottom: 0px;
    font-weight: 600;
}
.appointment-mobile .date th{
	padding-top: 0px;
	padding-bottom: 0px;
	background-color: #D0DFF3;
}
.appointment-mobile .hospital h5{
	margin: 0px;
	font-weight: bold;
	/*font-size: 20px;*/
}
.appointment-mobile .addr{
	margin-bottom: 5px !important;
}
.appointment-mobile .time{

}

.btn-calendar{
	padding:30px;
}
.btn-meeting{
	width:100%;
	height:60px;
	font-size:1.25em;
}
.btn-history{
	width: 25%;
	font-size: 1.5em;
}
.bg-social{
	background:#ffffff;
	border:#ECECEC 1px solid;
	min-height:60px;
}
.box-map-detail{
	background:#ffffff;
	border:#ECECEC 1px solid;
	padding: 10px 15px;
	min-height:275px;
	width:24%;
	float:right;
}
/*Calendar*/
.head-month{
	font-size:4em;
	padding: 50px 0px;
	font-family: 'Sukhumvit';
	font-weight: lighter;
	border-bottom: #484b51 3px solid;
}
.box-calendar{
	width: 20%;
	float:left;
	height:35px;
	margin:0px;
	padding: 10px;
	border:#eeeeee 1px solid;
}
.box-calendar-xs{
	width: 26.5%;
	float:left;
	height:35px;
	margin:0px;
	padding: 10px;
	border:#eeeeee 1px solid;
}

.box-meeting-small{
	width: 100%;
}
.box-meeting-table{
	width: 20%;
	margin: 0px auto;
	padding:5px;
	float:left;
}
.box-meeting-table:first-child{
	margin-left: 10%;
}
.box-meeting-table:last-child{
	margin-right: 10%;
}
.box-meeting-table:nth-child(odd){
	background-color:#E6EDF9;
}
.box-meeting-table_xs{
	width: 33%;
	margin: 0px auto;
	padding:5px;
	float:left;
	text-align:center;
}
.arrow-calendar{
	width: 3%;
	float:left;
	padding-top:60px;
}
.title-box-calendar{
	background:#F5F9FF;
	width: 100%;
	height: 30px;
	margin-bottom: 10px;
	color:#ffffff;
	padding: 0px 10px;
	font-size: 1.5em;
}
.box-calendar-white{
	border: #dddddd 1px solid;
	width:100%;
	background:#ffffff;
	padding:0px;
}
.title-history{
	border-bottom: #ededed 5px solid;
}
.line-verticle{
	border-right: #484b51 1px solid;
	margin: 30px 30px 0px 0px;
	min-height: 650px;
	position:absolute;
}
.icon-circle{
	position: absolute;
	background:url(../images/icon-circle.png) no-repeat;
	width: 50px;
	height: 50px;
	margin-top: 100px;
	margin-left:-20px;
	z-index:2;
}
.icon-subspecial{
	width: 50px;
	text-align:center;
	list-style:none;
	padding: 0px;
	margin: 10px;
	float:left;
}

.subspecial h1{
	margin-top: 10px;
}
.subspecial ul{
	padding: 0px;
	list-style: none;
}
.subspecial h3{
	color: #000;
}
.head-meeting{
	background:#4e4c4c;
	color:#fff;
	height:60px;
}
.box-arrow{
	width: 10%;
	height:35px;
	background:#e5e5e5;
	color:#ffffff;
}
.box-arrow_xs{
	width: 10%;
	height:60px;
	background:#e5e5e5;
	color:#ffffff;
}
.box-arrow-nav{
	height: 60px;
    padding: 0;
    text-align: center;
}
.box-meeting-table-wrap{
	border: 1px solid #E6EDF9;
    padding: 0;
}
.badge{
	padding: 0px 7px 3px 7px;
}
hr{
	border-top: 1px solid #DDDDDD !important;
}
.nav-tabs > li.active > a{
	background-color: #F9F6F6 !important;
}
.profile-pic-mobile{
	padding: 0px;
}
.profile-detail-mobile {
	text-align: center;
}
.profile-detail-mobile h1{
	font-size: 20px;
}
.timetable{
	margin-bottom: 50px;
}
.doctor-list{
	border: 1px solid #eaeaea;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
}
.detail-sidebar{
	border-left: 1px solid #CECECD;
    border-right: 1px solid #CECECD;
    padding: 0;
    margin: 0 15px 0 15px;
    background-color: #fff;
}
.detail-timetable{
	border: 1px solid #eaeaea;
}
.detail-timetable > h4, .detail-timetable > table{
	margin-bottom: 0px;
	margin-top: 0px;
}
.detail-timetable > div, .detail-timetable > table{
	margin-bottom: 0px;
	margin-top: 0px;
}
.detail-right{
	float: right;
}
.detail-font{
	font-size: 13px;
}
.article{
	background-color: #fff;
	border-radius: 10px;
	padding: 10px;
	margin-top: 10px;
}
.article h3{
	margin-top: 5px;
}
.btn-next{
	border-radius: 50%;
    padding: 5px;
    background: #6894F2;
    color: #fff;
}

.doctor-profile-block {
	position: relative;
	padding-top: 5px;
}

.doctor-profile-block h3 {
	color: #6c97ee;
}

.doctor-profile-block h3 > i {
	width:30px;
}

.doctor-profile-block > ul {
	position:relative;
	width:100%;
	overflow:hidden;
	clear:both;
}

.doctor-profile-block > ul > li {
	position: relative;
	padding-left: 30px;
}

.doctor-profile-block > ul > li:before {
    /* Unicode bullet symbol */
    content: '\2022 ';
    /* Bullet color */
    color: #6c97ee;
    padding-right: 0.5em;
	position: absolute;
	top: -8px;
	left: 5px;
	font-size: 30px;
}

/* mobile adjustment*/
@media (max-width: 991px) {
	.content{
		padding: 0px !important;
	}
	.doctor-short-detail{
		padding: 0px !important; 
	}
	body{
		letter-spacing: 0px;
	}
	.appointment-mobile .time .btn{
	    /*font-family: Helvetica, Arial, sans-serif;*/

	    /*font-size: 20px;*/
	    line-height: 1.4;
	    padding-bottom: 6px;
	    letter-spacing: 2px;
	    margin-top: 5px;
	    margin-left: 5px;
	}
	.short-detail-wrap{
		padding: 0px;
	}
	.detail-tab {
		padding: 0px;
	}
	.tab-content .col-xs-12{
		padding: 0px;
	}
	.timetable{
		padding: 0px;
	}

}

@media (min-width: 992px) {
	.detail{
		padding: 0px;
	}
	.detail > .col-xs-12{
		padding: 0px;
	}
}