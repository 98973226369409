.bg-search{
	height: 240px;
	padding-top: 30px;
	background:url(../images/banner1.png);
}
.schedule-content{
	border:#dedede 1px solid;
	padding: 20px 30px 10px 30px;
	background:#ffffff;
	box-shadow:0px 1px 5px rgba(50, 50, 50, 0.21);
}
.box-content{
	border: #dedede 1px solid;
    padding: 0px;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(50, 50, 50, 0.21);
}
.box-map-small{
	border:#dedede 1px solid;
	padding: 0px;
	background:#ffffff;
	box-shadow:0px 1px 5px rgba(50, 50, 50, 0.21);
}
.box-map-small iframe{
	pointer-events: none;
}
.box-profile{
	/*padding: 15px 30px 10px 30px;*/
	background:#ffffff;
	/*box-shadow:0px 1px 5px rgba(50, 50, 50, 0.21);*/
}
.box-profile > .profile{
	padding: 10px 15px 0 15px;
}
.box-schedule{
	border: #E6EDF9 solid 1px;
}
.box-appointment-action{
	border: #E6EDF9 solid 1px;
	background-color: #efefef;
}
.box-appointment-action > div{
	margin-top: 30%;
	font-size: 16px;
}
.box-appointment-action .open{
	color: #2b9c25;
}
.box-appointment-action .closed{
	color: #d02929;
}
.box-content_small{
	border:#dedede 1px solid;
	background:#ffffff;
	box-shadow:0px 1px 5px rgba(50, 50, 50, 0.21);
}
.logo-certificate{
	position: absolute;
	margin: 50% 0px 0px 0px;
	z-index: 1;
	border:#ffffff 3px solid;
}
.logo-certificate_small{
	position: absolute;
	margin: 53px 0px 0px 0px;
	z-index: 1;
	border:#ffffff 3px solid;
}
/*Booking section*/
.section-booking{
	padding: 30px 0px 0px 0px;
}
.line-title{
	border-bottom:#484b51 5px solid;
}
.line-gray{
	border-bottom:#f9f9f9 5px solid;
}
.line-location{
	/*padding-top: 40px;*/
	color:#84868b;
}
.logo-hospital-s img{
	width:10%;
}
.logo-hospital-xs img{
	width:13%;
}

.box-date{
	padding-top: 4px;
	/*border-bottom:#5e5e5e solid 1px;*/
	font-family: Helvetica, Arial, sans-serif;
	font-weight:400;
	font-size: 14px;
	min-height: 26px;
	/* border-left: #E6EDF9 solid 1px; */
	/* border-right: #E6EDF9 solid 1px; */
}
.box-date-sub{
	/* border-left: #E6EDF9 solid 1px; */
	border-bottom: #E6EDF9 solid 1px;
	min-height: 26px;
	/* border-right: #E6EDF9 solid 1px; */
}
.box-date-sub div{
	padding: 0px 0px 8px;
	line-height: 110%;
}
.box-datetime{
	min-height: 180px;
}
.box-datetime div{
	padding: 0px;
	line-height: 200%;
	min-height: inherit;
}
.box-datetime div:nth-child(odd){
	background-color: #E6EDF9;
}
.box-datetime .no-timeslot{
	position: absolute;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    width: 100%;
}
.box-datetime .no-timeslot > p{
	margin-top: 15%;
    width: 80%;
    margin-left: 9%;
    line-height: 1.2;
    font-size: 16px;
}
.box-datetime .inactive-timeslot a{
	padding: 40px;
}
.box-date .date-nav{
	position: absolute;
	margin: 0px 4px;
	cursor: pointer;
	/*z-index: 99;*/
}
.box-date .date-nav.next{
	right: 0;
}
.box-load{
	position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    background: rgba(220, 225, 235, 0.62);
    width: 100%;
    height: 100%;
}
.box-load .fa{
	position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 16px);
    font-size: 32px;
}
.bg-date{
	font-family: Helvetica, Arial, sans-serif;
	background:#f9f9f9;
	border-right:#ffffff 2px solid;
	border-top: #ffffff 2px solid;
	font-size: 14px;
}
.btn-booking{
	font-family: Helvetica, Arial, sans-serif;
	font-size: 14px;
	/*font-weight: 600;*/
	/*width:100%;*/
	border-radius: 4px;
	padding: 0 10px;
	display: block;
	margin: 5px 5px;
}
.btn-more{
	font-family: Helvetica, Arial, sans-serif;
	font-size:0.85em;
	cursor: pointer;
}
.title-hospital{
	font-family: 'Sukhumvit';
	font-weight: bold;
	background:#f2f7fa;
	padding: 0px 0px 0px 10px;
}
.btn-blue{
	color: #ffffff;
    background-color: rgb(78, 133, 219);
    border-color: #859DE2;
}

.btn-success-light{
	color: rgb(92, 184, 92);
    background-color: #ffffff;
    border-color: #4CAE4C;
}
.btn-link{
	color: #5e5e5e;
	padding: 10px;
}
.btn-link:hover{
	color: #5e5e5e;
}
.border-top-margin{
	width:112%;
	margin-left:-30px;
	border-top:1px solid #dddddd;
}
.btn-detail-doctor{
	height: 40px;
    font-size: 16px;
    line-height: 36px;
    background: url(../images/icon-Doctor-Detail.png) no-repeat;
    padding: 0px 0px 0px 50px;
    background-size: 39px;
}
.btn-detail-doctor:hover{
	color: #333;
}
.btn-blue-booking{
	height: 100px;
}
.line-booking-right{
	border-right: #dddddd 1px solid;
	height: 100px;
}
.icon-like{
	margin:0px;
	color:#e14761;
}
.icon-like-score{
	margin:0px;
	color:#e14761;
	font-size: 12px;
}
.box-btn-booking{
	margin-left: -18px;
	width:95px;
	padding: 20px;
}
.bg_slide_doctor{
	width: 100%;
	background:#dce1eb;
	padding: 20px;
	margin:0px;
}
.bg-gray-slideDoctor{
	background:#eeeeee;
}

.white48{
	font-weight:lighter;
	color:#ffffff;
	font-size:3em;
}
.search-header{
	padding-bottom: 10px;
    margin: 0px 0 2px;
    border-bottom: 1px solid #eeeeee;
}
.search-header-mobile{
	font-weight: bold;
    float: left;
    width: 65%;
    font-size: 1.5em;
	font-family: 'Sukhumvit';
	color:#5e5e5e;
	margin-top: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.insurance{
	border: 1px solid #C7D4FB;
    box-shadow: none;
    color: #4E85DB;
    background-color: #FAFCFF;
    padding: 0 7px;
}
.btn-group.insurace.open button {
    color: #E44E4E;
}
.search-result{
	margin-top: 125px;
}
.search-result-nosearch{
	margin-top: 60px;
}
.search-collapsed{
	position: absolute;
    right: 10px;
    height: 40px;
    padding: 10px;
    bottom: -40px;
    background-color: #F3F2F2;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: solid 1px #E4E4E5;
}
.box-absolute{
	position: absolute;
	height: 100%;
	width: 100%;
}
.box-absolute-timetable{
	position: absolute;
	height: calc(100% - 50px);
	width: 100%;
}
.box-absolute-time{
	height: 100%;
}

.dropdown-menu.searchbox-filter {
	width: calc(100% - 30px);
    margin: 5px 15px 0 15px;
    min-width: 0px;
}
.timeslot-wrap{
	background-color: #f5f5f5;
}
.timeslot-wrap .datetime{
	padding-top: 10px;
	font-size: 16px;
}
.timeslot-wrap > div{
	padding: 0 5px;
}
.timeslot{
	margin-bottom: 10px;
	padding-left: 0;
    padding-right: 5px;
}
.timeslot .btn{
	font-size: 16px;
    margin-top: 5px;
	margin-left: 5px;
	padding-left: 6px;
	padding-right: 6px;
}
.timeslot-nav{
	margin-bottom: 10px;
	padding-left: 0;
    padding-right: 5px;
}
.timeslot-nav .btn{
	font-size: 16px;
    margin-top: 5px;
	margin-left: 5px;
	padding-left: 15px;
	padding-right: 15px;
}
.timeslot .btn-blue-light{
	font-size: 16px;
}
.booking-slot{
	width: 70px;
}
.select-location{
    border-radius: 4px;
    padding: 2px 6px;
}
.select-location-mobile{
	color: #f28888;
    margin-left: 10px;
    font-weight: 100;
    font-size: 14px;
    float: left;
    margin-top: 5px;
    width: calc(35% - 10px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
}
.select-location-mobile:hover{
	color: #f28888;
    margin-left: 10px;
    font-weight: 100;
    font-size: 14px;
    float: left;
    margin-top: 5px;
    width: calc(35% - 10px);
    text-decoration: none;
    text-align: right;
}
.search-result .result-name{
	margin: 2px 0px;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.doctor_by_clinic-result{
	margin-top: 120px;
}

.nameMobile {
    width: 180px;
    display: inline-block;
    vertical-align: middle;
}

@media (max-width: 767px) {
	.search-result{
		margin-top: 115px !important;
		padding: 0;
	}
	.search-result .result-name{
	    font-size: 16px;
	}
	.search-result-nonav{
		margin-top: 100px !important;
		padding: 0;
	}
	.doctor_by_clinic-result{
		margin-top: 250px !important;
		padding: 0;
	}
	.modal-content{
		padding: 0 !important;
		min-height: 100%;
	}
	.btn-group-margin{
		padding-left: 2px;
	}
	.search-doc2{
		font-size: 20px;
	}
	.searchbox-filter a{
		padding-left: 10px !important;
	}
	.dropdown-menu.searchbox-filter {
		min-width: 100px;
	}
	.box-content{
		margin-bottom: 10px;
	}
	.bg-black_inside{
		padding-top: 10px;
		position: absolute !important;
	}
	.doctor-result{
		padding: 0px;
	}

}