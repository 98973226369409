.insurance-wrap{
	margin-top: 50px;
}
.insurance-wrap .btn{
	font-family: 'Kanit', sans-serif;
}
.insurance-wrap .btn-pink{
	color: #fff;
    background-color: #f10281;
    border-color: #eeeeee;
}
.insurance-wrap .insurance-box{
	background-color: #F3F2F2;
	padding: 50px 15px;
	border: 1px solid #e8e8e8;
}
.insurance-form{
	margin: 20px 0 100px 0;
}
.insurance-form label{
	font-size: 20px;
}
.insurance-wrap .insurance-detail h1{
	margin-top: 0;
}
.insurance-wrap .insurance-detail p{
	margin-bottom: 2px;
	font-size: 18px;
}
.insurance-wrap h1, 
.insurance-wrap h2, 
.insurance-wrap h3, 
.insurance-wrap h4 {
	font-family: 'Kanit', sans-serif;
}
.insurance-wrap {
	font-family: sans-serif;
}

.insurance-detail .detail-section{
	margin-right: 30px;
}

.insurance-benefit{
	margin-top: 25px;
}
.insurance-benefit .tile{
	border: 1px solid #aaa;
	border-radius: 4px;
	display: inline-block;
	padding: 10px 5px;
	min-width: 120px;
	margin: 10px 0;
}
.insurance-benefit .tile-block{
	display: block !important;
	padding: 2px 5px !important;
}
.insurance-benefit .tile ul{
	list-style: none; /* Remove list bullets */
    padding: 0;
    margin: 0;
}
.insurance-benefit .tile ul li{
	display: block;
}
.insurance-benefit .tile .tile-title{
	font-size: 32px;
    font-weight: bold;
    line-height: 0.9;
    color: #f10281;
}
.insurance-benefit .tile .tile-detail-1{
	font-weight: bold;
	color: #f10281;
}
.insurance-benefit .tile .tile-detail-2{
	margin-top: 5px;
	font-weight: bold;
}