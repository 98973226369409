footer{
	/*position: absolute !important;*/
	bottom: 0 !important;
}
body{
	background-color: none !important;
}
.or{
	float: left;
	font-size: 20px;
	width: 20%;
}
.line-or{
	width: 40%;
    height: 1px;
    background: #D4D1D1;
    margin-top: 14px;
    float: left;
}
.form-control{
	/*font-size: 22px !important;*/
}
.panel-default{
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2) !important;
}
.benefit {
	font-size: 24px;
	color: #fff;
}
.overlay {
	width: 100%;
    height: 100%;
    position: absolute;
    background: url(../images/banner.jpg) no-repeat;
	background-size: cover;
	-webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}
.overlay .doctor-background {
	background: rgba(0,0,0,0.5);
	width: 100%;
    height: 100%;
}
.birthday .birthday-input {
	width: 25%;
	display: inline-block;
}
.birthday .birthday-year {
	width: 34%;
	display: inline-block;
}