.article-title > h1{
	margin-bottom: 0;
}
.article-title > p {
	color: #aaa;
}

.page-content h1,
.page-content h2,
.page-content h3,
.page-content h4{
	color: #333;
}

.page-content h1:first-child,
.page-content h2:first-child,
.page-content h3:first-child,
.page-content h4:first-child{
	margin-top: 0px;
}

.sidebar{
	background-color: #fff;
}
.sidebar .item:first-child{
	padding-top: 15px;
}
.sidebar .item-doctor{
	padding-top: 15px;
    border: 1px solid #eee;
    padding-bottom: 15px;
}
.sidebar .item-doctor .item-doctor-image{
	float: left;
    width: calc(38% - 10px);
    margin-right: 10px;
}
.sidebar .item-doctor .item-doctor-description{
	float: left;
    width: 62%;
}
.sidebar .item-doctor .doctor-name{
	margin-bottom:0px; 
	font-size:16px; 
	font-weight: bold;
}
.sidebar .item-doctor .doctor-specialty{
	margin-bottom:0px; 
	font-size:16px; 
}
.sidebar .item-doctor .doctor-clinic{
	margin-top: 15px;
	margin-bottom:0px; 
	font-size:14px; 
	font-weight: bold;
}
.sidebar .item-doctor .doctor-address{
	margin-bottom:0px; 
	font-size:14px; 
}
.other-articles ul{
	font-size: 20px;
}
.page-blog-header{
	border-bottom: 1px solid #eee;
}
.page-blog-header > h3 {
	text-transform: uppercase;
	font-weight: bold;
	color: #000;
}
.author-img{
	padding: 0;
}
.author-img > .image{
	display: inline-block;
    width: 50px;
    height: 50px;
    background-position: center;
    background-position-y: 0;
    border-radius: 150px;
    background-size: cover;
    vertical-align: middle;
}
.author-img > .author{
	font-size: 20px;
	vertical-align: middle;
	margin-left: 10px;
	display: inline-block;
	width: 70%;
}

.article-page-content{
	font-size: 14px;
    padding-top: 30px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    font-weight: lighter;
    line-height: 1.7;
    font-family: Arial;
}
.featured-image{
	width: 100%;
    height: 400px;
    background-size: cover;
    margin-bottom: 25px;
    border: 1px solid #eee;
}
.featured-image-mobile{
	width: 100%;
	margin-bottom: 25px;
    border: 1px solid #eee;
}
.other-articles-title{
	padding: 15px 0 0 0;
}
.other-articles{

}
.other-article-item-wrap:nth-child(odd){
	padding: 0 5px 10px 0;
}
.other-article-item-wrap:nth-child(even){
	padding: 0 0 10px 5px;
}
.other-article-item{
    border: 1px solid #ddd;
}
.other-article-item .o-image{
	height: 100px;
    background: #eee;
    background-size: cover;
}
.other-article-item h5{
	padding: 10px 10px 0px;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: #000;
    line-height: 1.4;
}
.other-article-item .other-detail{
	padding: 0 10px 10px;
    margin: 0;
    font-size: 12px;
    color: #999;
}
.other-article-item .other-detail > .other-detail-name{
	float: left;
}
.other-article-item .other-detail > .other-detail-date{
	float: right;
}
.article-share{
	padding-left: 0px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #000;
}
.article-share > p{
	margin: 0;
}
.article-share-rigth{
    font-weight: bold;
    color: #000;
}

.social-box {
    text-align: center;
	}
.article-margin-left{
	margin-left: 10px;
}
.title-wrapper{
	padding: 0;
}
@media (min-width: 992px){
	.sidebar{
		padding-left: 0;
		padding-right: 0;
	}
	.social-box {
    	text-align: right;
	}
	

}

/*Line Button*/
.line-it-button{
	position:relative !important;
	top: 15px;
	display: inline;
	border-radius: 50%;
	width: 40px !important;
	height: 40px !important;
}
/*Facebook Like Button*/
.fb-like-button{
	position: relative !important;
    top: 10px;
    display: inline;
}