/*img {
	-webkit-filter: grayscale(30%); 
	filter: grayscale(30%);
}
html {
	-webkit-filter: grayscale(30%);
}*/

/* Fonts */
body {
  font-family: 'Sukhumvit';
  background-color: #fafafa;
  margin-bottom: 150px;
  color: #5e5e5e;
}

@media (max-width: 767px) {
	body {
	  font-family: 'Sukhumvit';
	  background-color: #fafafa;
	  margin-bottom: 260px;
	}

	.modal-content{
		padding: 0px !important;
	}
}

@media (device-height : 568px) 
		and (device-width : 320px) 
		and (-webkit-min-device-pixel-ratio: 2) {
	body {
		margin-bottom: 240px !important;
	}
}

html {
	position: relative;
  	min-height: 100%;
}

.font-th{
	font-size: 1.125em;
}
/* color */
.white{
	color:#ffffff;
}
.blue{
	font-family: 'Sukhumvit';
	color: #6c97ee;
}
.blue > a{
	color: #6c97ee;
}
.bold-blue{
	font-family: 'Sukhumvit';
	font-weight: bold;
	color: #6c97ee;
}
.blue14{
	font-family: Arial, Helvetica, sans-serif;
	color: #6c97ee;
	font-size: 0.875em;
}
.blue20{
	font-family: Arial, Helvetica, sans-serif;
	color: #6c97ee;
	font-size: 1.25em;
}
.blue62-light{
	font-family: 'Sukhumvit';
    font-weight: lighter;
    color: #6c97ee;
    font-size: 36px;
    font-weight: bold;
}
.drak-blue{
	color:#183766;
}
.bold-very-blue{
	font-family: 'Sukhumvit';
	font-weight: bold;
	color: #7a93c4;
}
.very-blue{
	font-family: 'Sukhumvit';
	color: #7a93c4;
}
.blue-original{
	font-family: 'Sukhumvit';
	color: #5698b0;	
}
.bold-blue-original{
	font-family: 'Sukhumvit';
	font-weight: bold;
	color: #5698b0;	
}
.orange{
	color: #e9ad2b;
}
.orange32{
	color: #e9ad2b;
	font-size: 2em;
}
.red{
	color:#f49c9c;
	font-size: 2em;
}
.red13{
	color:#f49c9c;
	font-size: 1em;
}
.light-blue{
	font-family: 'Sukhumvit';
	font-weight: lighter;
	color: #6c97ee;
}
.large{
	font-size: 1.5em;
	font-family: 'Sukhumvit';
	color:#5e5e5e;
	margin-top: 10px;
}
.x-large{
	font-size: 3.0em;
	font-family: 'Sukhumvit';
	color:#5e5e5e;
	margin-top: 0px;
}
.gray{
	color:#8b8f96;
}
.light-gray36{
	font-family: 'Sukhumvit';
	font-weight: lighter;
	color:#727171;
	font-size: 2.25em;
	font-weight:lighter;
}
.dark-gray{
	color:#333333;
}
.super-dark-gray{
	background:#4e4c4c;
}
.bg-white{
	background:#ffffff;
}

/* logo_ZeekDoc */
/*.logo {
    display: block;
    text-indent: -9999px;
    width: 180px;
    height: 25px;
    margin: 10px 0px 10px 15px;
    background: url(../images/logo_zeekdoc.svg);
    background-size: auto 25px;
    float: left;
    background-repeat: no-repeat;
}*/

.logo{
	float: left;
    height: 52px;
    padding: 0 0 0 15px;
}
.logo > img{
	height: 25px;
	margin-top: 12px;
}
.site-detail {
    float: left;
    height: 52px;
    font-size: 24px;
    line-height: 53px;
    margin-left: 20px;
}

/* Slogan */
.slogan {
	/*margin-bottom: 150px;*/
}
.slogan h1{
	font-family: 'Sukhumvit';
	font-weight: lighter;
	font-size: 4.5em;
	color: #FFFFFF;
	margin-bottom: 0px;
}
.slogan h1 strong{
	color: #FFFFFF;
}
.slogan h2{
	margin-top: 0px;
	color: #FFFFFF;
}
.slogan h2 strong{
	color: #FF9693;
	font-size: 48px;
}
.slogan2 h1{
	font-family: 'Sukhumvit';
	font-weight: lighter;
	font-size: 4.5em;
	color: #fff;
	margin-bottom: 0px;
	text-align: center;
}
.slogan2 h1 strong{
	color: #85ACE7;
}
.slogan2 h2{
	margin-top: 0px;
	text-align: center;
	color: #fff;
}
.slogan2 h2 strong{
	color: #D61F1B;
	font-size: 48px;
}
.slogan-mobile {
	margin-bottom: 0px;
	padding-bottom: 20px !important;
	padding-top: 50px;
	background-color: rgba(0, 0, 0, 0.5);
}
.slogan-mobile h1 {
	padding-top: 0px;
	font-size: 3.5em;
}
.slogan-mobile h2 strong {
	font-size: 36px;
}
.slogan-pc div{
	background-color: rgba(255,255,255,0.7);
	padding: 50px 50px 100px;
}
.slogan-pc2 div{
	padding: 20px 50px 50px;
}
/* Top link */
.top-link{
	background: #f9f9f9;
	padding-bottom: 40px;
}


/*Default btn*/
.btn-bg-blue{
	color: rgb(255, 255, 255);
	font-family: 'Sukhumvit';
	font-size: 18px;
	font-weight:bold;
	padding: 10px;
	text-shadow: 0px -1px 0px rgba(30, 30, 30, 0.8);
	background: rgb(133, 157, 226);
	background: -moz-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
	background: -webkit-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
	background: -o-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
	background: -ms-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
	background: linear-gradient(295deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
		-webkit-box-shadow: 0px 2px 1px rgba(50, 50, 50, 0.27);
		-moz-box-shadow:    0px 2px 1px rgba(50, 50, 50, 0.27);
	box-shadow:         0px 2px 1px rgba(50, 50, 50, 0.27);
	border-radius: 4px;
}
.btn-bg-blue-lg{
	color: rgb(255, 255, 255);
	font-family: 'Sukhumvit';
	font-size: 18px;
	font-weight:bold;
	padding: 26px;
	text-shadow: 0px -1px 0px rgba(30, 30, 30, 0.8);
	background: rgb(133, 157, 226);
	background: -moz-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
	background: -webkit-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
	background: -o-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
	background: -ms-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
	background: linear-gradient(295deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
		-webkit-box-shadow: 0px 2px 1px rgba(50, 50, 50, 0.27);
		-moz-box-shadow:    0px 2px 1px rgba(50, 50, 50, 0.27);
	box-shadow:         0px 2px 1px rgba(50, 50, 50, 0.27);
	border-radius: 4px;
}

.btn-bg-blue-xs{
	color: rgb(255, 255, 255);
	font-family: 'Sukhumvit';
	font-size: 18px;
	font-weight:bold;
	/*margin-bottom: 20px;*/
	padding: 4px;
	text-shadow: 0px -1px 0px rgba(30, 30, 30, 0.8);
	background: rgb(133, 157, 226);
	background: -moz-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
	background: -webkit-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
	background: -o-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
	background: -ms-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
	background: linear-gradient(295deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
		-webkit-box-shadow: 0px 2px 1px rgba(50, 50, 50, 0.27);
		-moz-box-shadow:    0px 2px 1px rgba(50, 50, 50, 0.27);
	box-shadow:         0px 2px 1px rgba(50, 50, 50, 0.27);
	border-radius: 4px;
}
.btn-bg-blue:hover{
	background: rgb(78, 133, 219);

}
.btn-bg-green{
	background: #ffffff;
	font-family: 'Sukhumvit';
	font-weight: bold;
	font-size: 18px;
	font-weight:bold;
	padding: 10px;
	color:rgb(20, 136, 20);
	border: 1px solid #eeeeee;
}
.btn-bg-green:hover{
	color: rgb(255, 255, 255);
	font-family: 'Sukhumvit';
	font-weight: bold;
	font-size: 18px;
	font-weight:bold;
	padding: 10px;
	text-shadow: 0px -1px 0px rgba(30, 30, 30, 0.8);
	background: rgb(92, 184, 92);
	background: -moz-linear-gradient(25deg, rgb(92, 184, 92) 50%, rgb(20, 136, 20) 0%);
	background: -webkit-linear-gradient(25deg, rgb(92, 184, 92) 50%, rgb(20, 136, 20) 0%);
	background: -o-linear-gradient(25deg, rgb(92, 184, 92) 50%, rgb(20, 136, 20) 0%);
	background: -ms-linear-gradient(25deg, rgb(92, 184, 92) 50%, rgb(20, 136, 20) 0%);
	background: linear-gradient(295deg, rgb(92, 184, 92) 50%, rgb(20, 136, 20) 0%);
		-webkit-box-shadow: 0px 2px 1px rgba(50, 50, 50, 0.27);
		-moz-box-shadow:    0px 2px 1px rgba(50, 50, 50, 0.27);
	box-shadow:         0px 2px 1px rgba(50, 50, 50, 0.27);
}

/* btn Call */
.call-bar{
		-webkit-box-shadow: -3px -12px 22px -16px rgba(0,0,0,0.38);
		-moz-box-shadow: -3px -12px 22px -16px rgba(0,0,0,0.38);
	box-shadow: -3px -12px 22px -16px rgba(0,0,0,0.38);
	border-bottom: #dddddd 1px solid;
}
.btn_call {
	color: rgb(108, 151, 238);
	font-size: 20px;
	width: 100px;
	height: 100px;
	float:left;
	padding-top: 33px;
	padding-left: 30px;
	background-image:url(../images/icon-call.png) no-repeat;
	display:block;
}
.btn_call_icon{
	font-size: 20px;
    width: 50px;
    height: 50px;
    float: left;
    background: url(../images/icon-Zeek-user2.png) no-repeat;
    background-size: 100%;
}
.zeekcall{
    font-size: 14px;
    width: 200px;
    line-height: 20px;
    margin: 5px 10px 0 0;
}
.zeekcall > .number{
	font-size: 18px;
}
a:hover.btn_call img{
	opacity: 0.5;
}
.text-call{
	margin-top: -20px;
	font-family: 'Sukhumvit';
	font-weight: bold;
	color:#ffffff;
	text-shadow:#999 60%;
}

/* Navigation */
.navbar-white{
	/*background-color: #fff;
    border-color: #e7e7e7;
    box-shadow: 0px 1px 10px #9599A1;*/
    background-color: #fff;
    border-color: #FFFFFF;
    box-shadow: 0px 1px 2px #E2E2E2;
}
.navbar-black{
	/*background-color: rgba(0,0,0,0.5);*/
	color: #fff;
}

.navbar-nav{
	font-size: 20px;
}
.navbar-nav a{
/* font tab profile */
	color: #5e5e5e;
	
}
.navbar-nav .lang-switch > a{
  line-height: 0.6;
}
.icon-bar{
	background-color: #333;
}

/* navbar-right */
.top-right{
	margin-right:30px;
	height: 50px;
	color: #FFF;
}

/* dropdown language*/
.box-lang{
	padding-top: 15px;
	font-size: 1em;
}
.lang-sm a{
	color: #cccccc;
	margin-top: 20px;
}
.lang-sm a:hover{
	color: #ffffff;
}

/* social */
.box-social{
	width: 100px;
	min-height: 50px;
	float:right;
}
.social-tw {
	width: 32px;
	height: 32px;
	margin: 5px;
	float:left;
	display:block;
	background: url(../images/tw.png) no-repeat;
} 
a:hover.social-tw {
	opacity: 0.8;
}
.social-fb {
	width: 32px;
	height: 32px;
	margin: 5px;
	float:left;
	display:block;
	background: url(../images/fb.png) no-repeat;
} 
a:hover.social-fb {
	opacity: 0.8;
}

/* menu */
.bg-menu{
	background:#eeeeee;
	border-radius: 0;
}
.menu-box{
	float: right;
	padding: 0px 10px;
	margin: 10px 10px 0px 0px;
	list-style:none;
	font-size:1.3em;
}
.menu-box a{
	color: #b7b7b7;
	font-family: 'Sukhumvit';
	font-weight: bold;
}
.menu-box a:hover{
	color: #727272;
	font-family: 'Sukhumvit';
	text-decoration:none;
	font-weight: bold;
}
.bg-gray{
	background:#f9f9f9;
}
.bg-blue{
	background:#e4e7ff;
}

/* Login in */
.btn-signin {
	padding: 11px;
	font-size: 20px;
	line-height: 1.8;
	border-radius: 0px;
	color: #fff;
	margin: 0px;
	background: rgb(167, 170, 177);
	background: -moz-linear-gradient(30deg, rgb(167, 170, 177) 50%, rgb(111, 110, 111) 0%);
	background: -webkit-linear-gradient(30deg, rgb(167, 170, 177) 50%, rgb(111, 110, 111) 0%);
	background: -o-linear-gradient(30deg, rgb(167, 170, 177) 50%, rgb(111, 110, 111) 0%);
	background: -ms-linear-gradient(30deg, rgb(167, 170, 177) 50%, rgb(111, 110, 111) 0%);
	background: linear-gradient(300deg, rgb(167, 170, 177) 50%, rgb(111, 110, 111) 0%);
	-webkit-box-shadow: 0px 1px 1px rgba(50, 50, 50, 0.21);
	-moz-box-shadow:    0px 1px 1px rgba(50, 50, 50, 0.21);
	box-shadow:         0px 1px 1px rgba(50, 50, 50, 0.21);
}
.btn-signin:hover{
	background:rgb(111, 110, 111);
	color: #fff;
}
.myButton {
	color: rgb(255, 255, 255);
	font-family: Arial, Helvetica, sans-serif;
	font-size: 18px;
	font-weight:bold;
	width: 15%;
	padding: 10px;
	text-shadow: 0px -1px 0px rgba(30, 30, 30, 0.8);
	background: rgb(133, 157, 226);
	background: -moz-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
	background: -webkit-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
	background: -o-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
	background: -ms-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
	background: linear-gradient(295deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
		-webkit-box-shadow: 0px 2px 1px rgba(50, 50, 50, 0.27);
		-moz-box-shadow:    0px 2px 1px rgba(50, 50, 50, 0.27);
	box-shadow:         0px 2px 1px rgba(50, 50, 50, 0.27);

}
.myButton:hover {
	color:#ffffff;
	opacity: 0.8;
	background:#336cc5;
}
.myButton:active {
	position:relative;
	top:1px;
}

.login-footer-bg{
	background:#f9f9f9;
	/*width: 109.7%;*/
	/*padding: 0px 55px;*/
	/*border-bottom:#6c97ee 2px solid;*/
	line-height: 7;
	/*margin:0px 0px -40px -40px;*/
}

.btn-specialty{
	color: #ffffff;
    background-color: rgb(78, 133, 219);
    box-shadow: 0px 2px 1px rgba(50, 50, 50, 0.27);
}

.btn-insurance{
	color: #ffffff;
    background-color: rgba(26, 69, 113, 0.33);
    box-shadow: 0px 2px 1px rgba(50, 50, 50, 0.27);
}

.btn-insurance:hover{
	background-color: rgb(26, 69, 113);
}


/* banner img */
.banner2 {
  background: url(../images/banner3.jpg) no-repeat;
  background-size: cover;
  margin-top:50px;
  /* padding-bottom: 50px; */
  border-bottom: 1px solid #FFFFDA;
  background-position-y: 50%;
}
.banner3 {
  background: url(../images/banner3.jpg) no-repeat;
  background-size: cover;
  margin-top:0px;
  /* padding-bottom: 50px; */
  border-bottom: 1px solid #FFFFDA;
  background-position-y: 80%;
}
.banner {
  background: url(../images/banner.jpg) no-repeat; 
  background-size: cover;
  margin-top:0px;
  padding-bottom: 0px;
  border-bottom: 1px solid #FFFFDA;
  background-position-y: 20%;
  background-position-x: 100%;
}
.banner-opacity{
  background: url(../images/banner1-white.jpg) no-repeat; 
  position: relative;
  z-index: 100;
  width: 100%;
	min-height: 600px;
}
.banner2 .search-box{
	background: rgba(0,0,0,0.7);
	width: 100%;
	padding: 25px 50px 15px 50px;
}
.banner3 .search-box{
	background: rgba(0,0,0,0.5);
	width: 100%;
	padding: 200px 50px 170px 50px;
}
.banner .search-box{
	background: rgba(0,0,0,0.5);
	width: 100%;
	padding: 150px 50px 170px 50px;
}
.search-box-mobile{
	background: rgba(0,0,0,0.5);
	width: 100%;
	padding-bottom: 150px;
	padding-top: 20px;
}
.search-box-pc{
	padding: 0px;
	box-shadow: 0px 2px 1px rgba(50, 50, 50, 0.27);
}
.search-location{
	color:#FFF; 
	font-size: 18px !important;
	margin: 15px 0 0 15px;
}
.search-location a{
	/* font-weight: bold; */
    color: #F28888;
    cursor: pointer;
    /*border: 1px solid #5A5858;*/
    padding: 0px 6px;
    border-radius: 4px;
    /*background-color: rgba(0, 0, 0, 0.17);*/
}
.search-location2{
	font-size: 16px !important;
	margin: 5px 0 0 15px;
	color: #333;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.search-location2 a{
	/* font-weight: bold; */
    color: #E44E4E;

    cursor: pointer;
}
.search-option{
	display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 15px;
	color: #333;
    font-size: 16px !important;
}



/* bg-white */
.bg-black{
	width: 100%;
	background-color: #6f6d6d;
	position: absolute;
	bottom: 0px;
	min-height: 160px;
    filter: alpha(opacity=80); /* For IE8 and earlier */
	z-index: 1000;
	margin: 0px;
	padding: 25px 0px;
}
.bg-black_inside{
	width: 100%;
    background-color: #F3F2F2;
    filter: alpha(opacity=80);
    z-index: 1000;
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 10px;
    position: fixed;
    top: 50px;
    border-bottom: 1px solid #E4E4E5;
}

.margin-btn{
	margin: 20px 5px 0px 20px;
}
.box-time{
	width: 42%;
	margin: 20px 0px 0px 15px;
	padding: 40px 30px;
	background:#f9f9f9;
}

/* glyphicon-label */
.glyphicon-certificate {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
	font-size: 1.5em;
  font-style: normal;
  font-weight: normal;
	color: #e3c000;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	shadow: 0 8px 6px -6px black;
	-webkit-shadow: 0 8px 6px -6px black;
	-moz-shadow: 0 8px 6px -6px black;

}
.font-label{
	position:relative;
	margin-top:-95px;
	color:#FFF; 
	left: 365px;
	text-shadow:#999 60%;
}

/*glyphicon-search*/
.box-search{
	height: 50px;
	background:#ffffff;
}

.glyphicon-search{
	margin-left: 20px;
	font-size: 3em;
	font-weight:lighter;
}
.icon-search {
	width: 50px;
	height: 50px;
	margin: 10px 0px 0px 30px;
	background:url(../images/icon-search.png) no-repeat;
	display: block;
}
a:hover.icon-search {
	opacity: 0.5;
	color:#fff;
}
.line-left{
	border-left:#eeeeee solid 1px;
}
.line-right{
	border-right:#5e5e5e solid 1px;
}
.line-bottom{
	width: 10%;
	margin: 50px auto;
	border-bottom:#5e5e5e solid 1px;
}
.line-black{
	border-bottom:#5e5e5e solid 1px;
}
.line-blue{
	border-bottom:#acc4f5 solid 5px;
}
.border-bottom{
	border-bottom:#dcddde solid 1px;
}
/* box map*/
.box-map{
	position:absolute;
	right: 0px;
	top:10px;
	width: 300px;
	height:300px;
	padding: 5px;
	z-index: 100;
	opacity: 0.9;
}
.box-map-top{
	width: 98%;
	height: 100px;
	padding: 5px;
	font-size: 2em;
	font-weight:600;
	position:relative;
	overflow:hidden;
}
.box-map-bottom{
	width: 48%;
	height: 130px;
	margin-right: 5px;
	margin-top: 5px;
	font-size: 1.5em;
	position:relative;
	float:left;
	line-height: 0.3;
}
.text-map-bottom{
	top: -28px;
	position:relative;
	text-align:left;
	padding: 25px;
}
.icon-map{
	width: 53px;
	height: 71px;
	background:url(../images/icon-pin.png) no-repeat;
	position:relative;
	margin-top: 15px;
	margin-right: 15px;
	float:right;
	display: block;
	z-index:1000;
}
.icon-map:hover{
	width: 53px;
	height: 71px;
	background:url(../images/icon-pin_hover.png) no-repeat;
}
.icon-map-small{
	width: 20px;
    height: 25px;
    background: url(../images/icon-pin.png) no-repeat;
    position: relative;
    display: inline-block;
    background-size: 20px;
    top: 6px;
}
.icon-map-small-mobile{
	width: 15px;
    height: 22px;
    background: url(../images/icon-pin.png) no-repeat;
    position: relative;
    display: inline-block;
    background-size: 15px;
    top: 6px;
}

/* btn map */
.btn-map {
	position:relative;
	width: 98%;
	top: 5px;
	color:#FFF;
	background-color:#80a5e6;
	border-color:#8db0ec;
	font-size: 1.8em;
}
.btn-map:hover{
	color:#FFF;
	background-color:#6c97ee;
	border-color:#527bce;
}

/* icon map */
.icon-map-sm{
	font-size: 4em;
	margin: 10px;
	color: #4a4d53;
}

/* btn-doctor */
.btn_doctor {
	color: #4D5969;
	font-size: 1.5em;
	padding: 0px 10px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #BCC1CA;
}
.btn_doctor_black {
	color:#dbaa00;
	font-size: 1.3em;
	margin: 10px;
	height: 80px!important;
	text-shadow: 0px -1px 0px rgba(30, 30, 30, 0);
	background: rgb(245, 248, 253);
	background: -moz-linear-gradient(30deg, rgb(245, 248, 253) 50%, rgb(255, 255, 255) 0%);
	background: -webkit-linear-gradient(30deg, rgb(245, 248, 253) 50%, rgb(255, 255, 255) 0%);
	background: -o-linear-gradient(30deg, rgb(245, 248, 253) 50%, rgb(255, 255, 255) 0%);
	background: -ms-linear-gradient(30deg, rgb(245, 248, 253) 50%, rgb(255, 255, 255) 0%);
	background: linear-gradient(300deg, rgb(245, 248, 253) 50%, rgb(255, 255, 255) 0%);
	-webkit-box-shadow: 0px 1px 1px rgba(50, 50, 50, 0.21);
	-moz-box-shadow:    0px 1px 1px rgba(50, 50, 50, 0.21);
	box-shadow:         0px 1px 1px rgba(50, 50, 50, 0.21);
	background-image:url(../images/icon-call.png) no-repeat;
	display:block;
	background-image:url(../images/icon-call.png) no-repeat;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.btn_doctor_black:hover {
	opacity:0.5;
}
.btn_shadow {
	color: rgb(108, 151, 238);
	text-shadow: 0px -1px 0px rgba(30, 30, 30, 0);
	background: rgb(245, 248, 253);
	background: -moz-linear-gradient(30deg, rgb(245, 248, 253) 50%, rgb(255, 255, 255) 0%);
	background: -webkit-linear-gradient(30deg, rgb(245, 248, 253) 50%, rgb(255, 255, 255) 0%);
	background: -o-linear-gradient(30deg, rgb(245, 248, 253) 50%, rgb(255, 255, 255) 0%);
	background: -ms-linear-gradient(30deg, rgb(245, 248, 253) 50%, rgb(255, 255, 255) 0%);
	background: linear-gradient(300deg, rgb(245, 248, 253) 50%, rgb(255, 255, 255) 0%);
	-webkit-box-shadow: 0px 1px 1px rgba(50, 50, 50, 0.21);
	-moz-box-shadow:    0px 1px 1px rgba(50, 50, 50, 0.21);
	box-shadow:         0px 1px 1px rgba(50, 50, 50, 0.21);
	display:block;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.btn-margin{
	margin: 0px 10px 0px 0px;
}
.btn-submit-doctor{
	background:url(../images/btn-submit.png) no-repeat;
	width: 141px;
	height: 141px;
	color:#FFF;
	border: 0px;
	font-size: 2em;
}
.btn-submit-doctor:hover{
	opacity: 0.8;
}
.btn-width100{
	width: 100%;
	margin-left: -18px;
}
.btn-width52{
	width: 52%;
	margin-left: 6px;
}
.btn-local_large{
	width: 88%;
	font-family: 'Sukhumvit';
	font-weight: bold;
}
.btn-local_small{
	font-family: 'Sukhumvit';
	font-weight: bold;
	float:left;
	height: 35px;
}
.btn-font-black{
	color: #484b51;
	font-size: 22px;
}
.btn-group-margin{
	/*border-radius: 0px;*/
    margin-right: 14px !important;
}
.icon-local{
	color:#ffffff;
	font-size:1.3em;
	background:#9599A1;
	padding: 5px;
}
.icon-insurance{
	color:#ffffff;
	font-size:1em;
	padding: 12px;
	background:url(../images/icon-insurance.png) no-repeat #9599A1 center;
}
/* line */
.linetop { 
	border-top: 6px solid #6c97ee;
	margin-top: -5px;
	width: 10%;
	position: absolute;
}
.linebottom { 
	border-top: 1px solid #dad7d7; 
}
.line-dot{
	border-bottom:dotted #9599a0 0.125em;
}
.hr-footer{
	/*margin-top: 22px;*/
	margin-bottom:22px;
	border:0px;
	border-top:1px solid #666666;
}
/* img body */
.img-body{
	background:url(../images/img-body.png) center no-repeat;
	min-height: 1300px;
}

.size-icon{
	width: 40px;
	height: 40px;
}

/* chat */
.box-chat{
	height: 95px;
    z-index: 1000;
    background: url(../images/icon-chat-full.png) no-repeat right;
    position: fixed;
    bottom: 20px;
    width: 80px;
    right: 20px;
    background-size: 80px;
}


/* bg footer*/
.bg-footer{
	background: #4c4949;
    color: #aaa;
    margin-top: 20px;
    position: absolute;
    width: 100%;
    font-size: 14px;
    bottom: 0;
}
.box-footer-right{
	/*line-height: 3px;*//
	font-size: 1.16em;
	margin-top: 30px;
}
.box-footer-right a{
	color: #CCCED2 !important;
}
.box-footer-right a:hover{
	color: #CCCED2 !important;
}
.hidden-footer{
	height: 20px;
}
.padding10{
	padding:10px;
}
.padding20{
	padding: 20px;
}
.padding30{
	padding: 30px;
}
.padding40{
	padding: 40px;
}
.padding50{
	padding: 50px;
}


/* login box */
.tab-login{
	font-size: 1.25em;
	padding-top: 0;
    padding-bottom: 0;
}
.login-box{
	font-size: 1.25em;
}

/*Benefit*/
.login-tab-blue{
	border-top: #1e6e90 2px solid;
	/*width: 18%;*/
	font-size: 1.5em;
	font-family: 'Sukhumvit';
	font-weight: bold;
}
.regis-tab-org{
	border-top: #f79d11 2px solid;
	/*width: 18%;*/
	font-size: 1.5em;
	font-family: 'Sukhumvit';
	font-weight: bold
}
.regis-tab-org :active{
	border-right: none;
}
.box-benefit{
	border:#cccccc 1px solid;
	border-top: #1e6e90 2px solid;
	padding: 20px 0px;
	margin-bottom: 20px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
/*btn-fb*/
.btn-fb{
	/*background-image:linear-gradient(to bottom, #143e7f 100%, #143e7f 100%);*/
	background-color: #3a5795;
	background-repeat:repeat-x;
	border-color:#245580;
	line-height:1.5;
	color:#ffffff;
	font-weight:600;
	font-size:1em;
	font-family:Arial, Helvetica, sans-serif;
	margin: 0 0 20px 0;
	border-radius: 4px;
}
a:hover.btn-fb{
	color:#ffffff;
	opacity: 0.8;
	background:#3a5795;
}
a:active.btn-fb{
	color:#ffffff;
	opacity: 0.8;
}

/*margin*/

.line-height{
	line-height: 1;
}
.line-height3{
	line-height: 0.3;
}
.margin-right10{
	margin-right: 10px;
}
.margin-right{
	margin-right: -25px;
}
.margin-left{
	margin-left: 20px;
}
.margin-top10{
	margin-top: 10px;
}
.margin-top20{
	margin-top: 20px;
}
.margin-top30{
	margin-top: 30px;
}
.margin-top50{
	margin-top: 50px;
}
.margin-top120{
	margin-top: 120px;
}
.margin-top-10{
	margin-top: -10px;
}
.margin-top-20{
	margin-top: -20px;
}
.margin-top-30{
	margin-top: -30px;
}
.margin-top-50{
	margin-top: -50px;
}
.margin-bottom30{
	margin-bottom: 30px;
}
.margin-bottom-20{
	margin-bottom: -20px;
}
/*arrow*/
.arrow-light{
	color:#b9bdc4;
}
.border-subspecialty{
	border: #6c97ee 1px solid;
	border-radius: 12px;
	color:#6c97ee;
	padding: 0px 5px;
	font-size: 1.25em;
}
.btn-bg-doctor{
	height:49px;
	width:100%;
	text-shadow: 0px -1px 0px rgba(30, 30, 30, 0);
	background: #a7aab1;
}
.btn-bg-doctor:hover{
	color:#CCC;
}
.height350{
	height: 350px;
}
.box-arrow{
	width: 3%;
	float:left;
}
.box-arrow_right{
	width: 3%;
	float: right;
}
.box-slide-doctor{
	width: 94%;
	float:left;
}
.search-doc{
	width: 100%;
    /* height: 90px; */
    background: none;
    border: none;
    font-size: 18px;
    line-height: 0.9em;
}
.search-doc2{
	width: 100%;
	background: none;
	border:none;
	font-size:20px;
}
.search-doc3{
	width: 100%;
	border:none;
	font-size:1.5em;
}
.panel-body{
	font-size: 14px;
}
.panel-default > .panel-heading{
	background-color: #4E85DB;
    color: #fff;
    font-size: 20px;
}
.white-bg{
	background-color: #fff;
}
.grey-bg{
	background-color: #f5f5f5;
}
/* Google map Autocomplete */
.pac-container {
	z-index: 9999;
}
/**/
.angular-google-map-container { 
  	height: 300px; 
}
.sidebar.fix {
    position: fixed;
    top: 162px;
    right: calc(10% - 55px);
    width: 30%;
    height: calc(100% - 162px);
}
.sidebar .angular-google-map-container {
    height: 100%;
}
.sidebar .angular-google-map{
	height: 100%;
}
.sidebar.map{
	position: fixed;
    top: 162px;
    right: calc(10% - 55px);
    width: 30%;
    height: calc(100% - 162px);
}
.clickable{
	cursor: pointer;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mobilesearch{
	margin-top: 10px; 
	position: fixed; 
	z-index: 1000; 
	top: 50px;
}

.btn-mobilesearch {
	background: #fff;
    color: #6C97EE;
    border-color: #6C97EE;
    border-radius: 4px;
}
.btn-mobilelocation{
	background: none;
    color: #6C97EE;
    border-radius: 4px;
    padding: 0;
}
.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.ui-select-container .btn{
	border: 1px solid #BCC1CA;
	border-radius: 4px;
}
.login-content{
	padding-top: 90px;
}
.btn-facebook{
	color: #ffffff;
    background-color: #3F5C9A;
    border-color: #3F5C9A;
}
.btn-zeekdoc{
	color: rgb(255, 255, 255);
    font-family: 'Sukhumvit';
    font-size: 18px;
    font-weight: bold;
    padding: 5px 15px 5px;
    ackground: rgb(133, 157, 226);
    background: -moz-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
    background: -webkit-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
    background: -o-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
    background: -ms-linear-gradient(25deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
    background: linear-gradient(295deg, rgb(133, 157, 226) 50%, rgb(78, 133, 219) 0%);
    -webkit-box-shadow: 0px 2px 1px rgba(50, 50, 50, 0.27);
    -moz-box-shadow: 0px 2px 1px rgba(50, 50, 50, 0.27);
    box-shadow: 0px 2px 1px rgba(50, 50, 50, 0.27);
    border: none;
    font-size: 18px;
}
.pagination a{
	font-size: 20px;
}
.page-container{
	margin-top: 50px;
}
.page-content{
	font-size: 18px;
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 20px;
    font-weight: lighter;
    line-height: 1.7;
}
.page-content p, .page-content p *{
	word-break: break-word;
}
.panel{
	border: none !important;
}
.btn-blue-opaque{
	color: #ffffff !important;
	background-color: rgb(78, 133, 219) !important;
	border-color: #859DE2;
}
.btn-blue-opaque:hover{
	color: rgb(78, 133, 219) !important;
    background-color: #ffffff !important;
}
.btn-blue-light{
	color: rgb(78, 133, 219) !important;
    background-color: #ffffff;
    border-color: #859DE2;
}
.btn-blue-light:hover{
	color: #ffffff !important;
	background-color: rgb(78, 133, 219) !important;
}
.btn-grey{
    color: #6f6f6f !important;
    background-color: #eee;
    border: none;
}
.btn-orange-light{
	color: #ce913b !important;
    background-color: #ffffff;
    border-color: #f0ad4e;
}
.btn-orange-light:hover{
	color: #ffffff !important;
	background-color: #f0ad4e !important;
}
.btn-link:hover{
	color: #5e5e5e !important;
}
.btn-fix-appointment{
	position: fixed;
    bottom: 0;
    z-index: 16000003;
    padding: 10px;
    width: 100%;
    font-size: 24px;
    background-color: rgb(92, 184, 92);
    color: #fff !important;
    border-radius: 0;
    font-family: helvetica;
}
.btn-turquoise{
	background-color: #79c7ce;
	border-color: #dedede;
	color: #fff;
}
.btn-turquoise:hover{
	background-color: #79c7ce;
	border-color: #dedede;
	color: #fff;
}
.btn-darkblue{
	background-color: #53528e;
	border-color: #dedede;
	color: #fff;
}
.btn-darkblue:hover{
	background-color: #53528e;
	border-color: #dedede;
	color: #fff;
}

.navbar-btn{
    margin: 8px 4px 0 4px !important;
    padding: 6px !important;
}

.dropdown-menu.search-filter {
	width: 100%;
    min-width: 0px;
}
.lang-switch ul{
	min-width: 100%;
}
.lang-switch li a{
	padding: 8px 10px;
	line-height: 1;
}
.btn-group-green-active .btn.active{
	color: #ffffff;
    background-color: #449d44;
    border-color: #398439;
}
.btn-cancel{
	color: #e60000;
    border-color: #e60000;
}
.btn-change{
	color: #fb7f00;
    border-color: #fb7f00;
}
.insurance-service-wrap{
	margin-top: 40px;
}
.share-icon{
	width: 25px;
}
.rating-input > i{
	cursor: pointer;
}
.auto-width {
	width: auto !important;
}
.share-icon-bigger{
	width: 40px;
	height: 40px;
}
.text-red{
	color: #f44336;
}
.has-error .form-control{
	border-color: #f44336;
}

.inner-addon {
	position: relative;
}
  
/* style glyph */
.inner-addon .fa {
	position: absolute;
	padding: 15px 10px;
	pointer-events: none;
}

.search-box.inner-addon .fa {
	padding: 10px 10px !important;
}

/* align glyph */
.left-addon .fa  { left:  0px;}
.right-addon .fa { right: 0px;}

/* add padding  */
.left-addon input  { padding-left:  35px; }
.right-addon input { padding-right: 30px; }

.modal-dialog {
	margin: 0px !important;
	height: 100%;
}

/*# sourceMappingURL=custom.css.map */