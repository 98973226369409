.bg-about{
	background:#fafafa;
}
.bg-about-zeekdoc{
	background-color: #729BEE;
    color: #fff;
    padding: 30px;
}
.about-zeekdoc-wrap{
	padding: 0;
}
.about-head{
	text-align:center;
	padding: 10px 0px 40px;
	font-size: 1.25em;
}
.benefit-head{
	text-align:center;
	padding: 40px 0px 80px;
	font-size: 1.25em;
}
.about-zeekdoc{
	text-align:center;
	padding: 0px 0px 20px;
	font-size: 1.25em;
}
.about-zeekdoc .about-zeekdoc-benefit{
	height: 250px;
}
.about-zeekdoc h2{
	font-size: 24px;
	font-weight: bold;
}
.box-label{
	margin: 0px auto;
	left: 350px;
	height: 200px;
}
.label-ribbon{
	font-family: 'Sukhumvit';
	font-size: 2.65em;
	height: 70px;
	line-height: 70px;
	text-align:center;
	position: relative;
	width: 80%;
	margin: 30px 10px 10px -70px;
	color: #FFF;
	background-color: #85ace7;
	text-shadow: 0px 1px 2px #bbb;
	-webkit-box-shadow: 0px 2px 4px #888;
	-moz-box-shadow: 0px 2px 4px #888;
	box-shadow: 0px 2px 4px #888;
}
.label-ribbon:after
{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: 0px;
	top: 100%;
	border-width: 12px 30px;
	border-style: solid;
	border-color: #4d76b5 #4d76b5 transparent transparent;
}
.label-ribbon:before
{
	content: ' ';
	position: absolute;
	width: 140px;
	height: 0;
	left: -80px;
	top: 33px;
	border-width: 30px 10px;
	border-style: solid;
	border-color: #85ace7 #85ace7 #85ace7 transparent;
}
.label-ribbon2{
	font-family: 'Sukhumvit';
	font-size: 2em;
	text-align:center;
	position: relative;
	width: 80%;
	padding: 1px 20px 1px 70px;
	margin: -57px 0px 10px -70px;
	color: #FFF;
}
.label-ribbon2:after
{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	right: 0px;
	top: 2350%;
	border-width: 12px 30px;
	border-style: solid;
	border-color: #4d76b5 transparent transparent #4d76b5;
}
.label-ribbon2:before
{
	content: ' ';
	position: absolute;
	width: 140px;
	height: 0;
	right: -80px;
	top: 11px;
	border-width: 30px 10px;
	border-style: solid;
	border-color: #85ace7 transparent #85ace7 #85ace7 ;
}

.line-dot{
	border-bottom: dotted 2px #484b51;
	width: 40%;
	margin: 80px auto;
}
.icon-star{
	font-size: 22px;
	color:#4d76b5;
	margin: 0px 20px;
}
.banner-sponsor img{
	-webkit-filter: grayscale(100%); 
	filter: grayscale(100%);
	width: 200px;
	padding: 20px;
}
.navbar-header-custom{
	padding: 0 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #4E85DB;
}
.navbar-black .navbar-nav a{
	color: #fff !important;
}
.navbar-black .navbar-nav a:hover{
	color: #fff !important;
	background-color: none !important;
}
.navbar-black .logo{
	background-image: url(../images/logo_zeekdoc_white.png);
}