#contact-us {
	font-size: 20px;
	padding-bottom: 50px;
}

#contact-us h1{
	color: #4694FB;
	padding:10px 0;
	margin:0;
}

#contact-us small{
	padding: 10px 0;
}

#contact-us .frame{
	margin-top: 1.5em;
	background: white;
	padding: 20px 0;
	border-radius: 20px;
}
#contact-us .contact-address{
	margin: 0px;

}
#contact-us p.title {
	font-size: 36px;
	margin: 0px
}

#contact-us .by-contact{
	margin: 20px 0 30px 0;
}

#contact-us .by-contact p{
	margin: 0;
}

#contact-us .contact-detail{
	margin: 20px 0;
}