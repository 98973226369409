.bg_blue {
    background: #85ace7;
}

.arrow-down {
    position: relative;
    left: 25%;
    width: 0;
    height: 0;
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
    border-top: 20px solid #85ace7;
}

.img-joinUs {
    max-width: 250px;
    margin: 25px 0px;
}

.icon-join {
    background: url(../images/icon-join.png) no-repeat center;
    margin: auto;
    width: 144px;
    height: 144px;
    margin-top: -90px;
}

.box-joinUs {
    margin: 0px;
    border: #dedede 1px solid;
    background: #ffffff;
    padding: 30px;
}

.light-blue {
    color: #D9E8FF;
}

.testimonial .description {
    margin-bottom: 30px;
    line-height: 1.4;
}

.action-btn {
    background-color: #85ACE7;
    height: 100%;
    margin: 0;
    width: 100%;
    position: fixed;
}
.waiting-for-approval{
	padding-top: 20px;
    padding-bottom: 40px;
    margin-top: 60px;
}
.waiting-for-approval p{
	line-height: 1.5;
}
.waiting-for-approval h4{
	line-height: 1.5;
	margin-top: 40px;
}
.waiting-for-approval h3{
	line-height: 1.5;
}
.waiting-for-approval .suggest-email{
	padding: 10px;
}

@media (max-width: 767px) {
    .testimonial .title {
        margin-top: 0;
    }
    .testimonial .description {
        margin-top: 30px;
    }
    .action-btn {
        position: relative !important;
        height: 300px !important;
    }
}

.btn-turquoise,
.btn-clinic-register {
    background-color: #2b44e7;
}

.btn-darkblue,
.btn-doctor-register {
    background-color: #e4ac3b;
}

.joinus .clinic-selected {
    width: 0;
    height: 0;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-bottom: 60px solid #fff;
    position: absolute;
    border-radius: 0;
    margin-top: 216px;
    margin-right: 82px;
    z-index: 1;
    margin-left: 84px;
}

.joinus .clinic-mobile-selected {
    width: 0;
    height: 0;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-bottom: 60px solid #fff;
    position: absolute;
    border-radius: 0;
    margin-top: 136px;
    margin-right: 82px;
    z-index: 1;
    margin-left: 10px;
}

.input-group input,
.input-group button {
    border-radius: 0 !important;
}

.has-error .input-group button {
    border-color: #f44336;
}