.joinus{
	font-family: 'Arial', sans-serif;

}
.joinus h1, 
.joinus h2, 
.joinus h3, 
.joinus h4 {
	font-family: 'Sukhumvit', sans-serif;
}
.joinus .joinus-pricing-wrap h2{
	font-size: 40px;
	color: #2d3e70;
	font-weight: bold;
	margin-bottom: 30px;
}
.joinus .joinus-pricing-wrap ul{
	list-style: none;
	padding: 10px;
}
.joinus .joinus-pricing-wrap .pricing-module{
	-webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
.joinus .joinus-pricing-wrap .pricing-title{
	color: #fff;
	font-size: 24px;
	background: #2d3e70;
	text-align: center;
	padding: 10px;
}
.joinus .joinus-pricing-wrap .pricing-number{
	font-size: 24px;
	text-align: center;
	background-color: #eee;
	padding-bottom: 10px;
}
.joinus .joinus-pricing-wrap .pricing-number span{
	font-size: 14px;
}
.joinus .joinus-pricing-wrap .pricing-thumbnail{
	text-align: center;
	background-color: #eee;
}
.joinus .joinus-pricing-wrap .pricing-thumbnail img{
	margin: 20px;
	height: 88px;
}
.joinus .joinus-pricing-wrap .pricing-content {
	font-size: 14px;
}
.joinus .joinus-pricing-wrap .pricing-content li{
	padding: 5px;
}
.joinus .joinus-pricing-wrap .pricing-content .fa{
	margin-right: 10px;
}
.joinus .joinus-pricing-wrap .pricing-content .fa-check{
	color: #2ecc71;
}
.joinus .joinus-pricing-wrap .pricing-content .fa-times{
	color: #a94442;
}
.joinus .joinus-benefit-wrap h2{
	font-size: 40px;
	color: #2d3e70;
	font-weight: bold;
}
.joinus h4{
	font-size: 20px;
	color: #85ace7;
	font-weight: bold;
}
.jumbotron-joinus{
	background-color: #85ACE7;
	color: #fff;
	min-height: 430px;
}
.jumbotron-joinus h2{
	font-size: 40px;
    font-weight: bold;
}
.jumbotron-joinus .app-image{
	margin-top: 50px;
	width: 100%;
	position: absolute;
}
.jumbotron-joinus .app-image-xs{
	width: 60%;
	margin-top: 50px;
}
.jumbotron-joinus .jumbotron-detail{
	margin-top: 60px;
}
.jumbotron-joinus .jumbotron-detail p{
	font-family: 'Sukhumvit';
	margin-top: 20px;
	margin-bottom: 20px;
}
.joinus .joinus-pricing-wrap{
	padding-bottom: 50px;
}
.joinus .joinus-benefit-wrap{
	padding-top: 60px;
	padding-bottom: 50px;
}
.joinus .joinus-benefit{
	padding-top: 40px;
}
.joinus .joinus-benefit img{
	width: 100%;
	padding: 10px 20px;
}
.joinus .joinus-benefit .joinus-benefit-item{
	padding: 0 50px;
	text-align: center;
}
.jumbotron-register{
	background-color: #85ACE7;
	color: #fff;
	padding-bottom: 20px;
}
.jumbotron-register h2{
	font-size: 40px;
	font-weight: bold;
}
.jumbotron-register p{
	font-family: 'Sukhumvit', sans-serif;
}
.btn-clinic-register{
	color: #fff;
	background-color: #79c7ce;
	font-size: 24px;
	font-family: 'Sukhumvit', sans-serif;
	text-align: center;
	border: solid 5px #ffffff;
	border-radius: 10px;
	height: 240px;
	width: 240px;
	margin: 0 20px;
}
.btn-doctor-register{
	color: #fff;
	background-color: #53528e;
	font-size: 24px;
	font-family: 'Sukhumvit', sans-serif;
	text-align: center;
	border: solid 5px #ffffff;
	border-radius: 10px;
	height: 240px;
	width: 240px;
	margin: 0 20px;
}
.btn-register:hover{
	color: #fff;
}
.btn-register.inactive{
	color: #fff;
    filter: alpha(opacity=50);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .50;
}
.joinus-form h2{
	text-align: center;
	font-weight: bold;
	color: #2d3e70;
}
.joinus .clinic-selected{
	width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 60px solid #fff;
    position: absolute;
    border-radius: 0;
    margin-top: 215px;
    margin-left: 112px;
    z-index: 1;
}

.joinus-form label{
	font-weight: 300;
	font-family: 'Sukhumvit';
}
.joinus-form .form-group{
	margin-bottom: 20px;
}
.joinus-form input{
	border-radius: 0px;
	box-shadow: none;
}
.joinus .btn-submit {
	border-radius: 5px;
  	background-color: #cb832b;
  	box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.26);
  	color: #fff;
  	font-size: 18px;
  	font-family: 'Sukhumvit';
  	padding: 10px 80px;
}
.joinus-form{
    padding-bottom: 50px;
}
.joinus .intl-tel-input{
	display: block;
}
.joinus .recaptcha{
	margin-bottom: 20px;
}
.btn-register.btn-mobile{
	width: 140px !important;
    height: 150px !important;
    margin: 0 0px !important;
    border: solid 1px #ffffff;
}
.btn-register.btn-mobile p{
	font-size: 18px;
	margin: 5px;
}
.btn-register.btn-mobile img{
	height: 90px;
}
.register-mobile-group .clinic-selected{
	width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 60px solid #fff;
    position: absolute;
    border-radius: 0;
    margin-top: 145px;
    margin-left: 40px;
    z-index: 1;
}
.register-group .register-link img {
    width: 250px;
}
.register-group .register-link {
    display: inline-block;
    padding: 0 20px;
}
.register-group .register-link a {
	text-decoration: none;
}
.register-group .register-link h3 {
    margin: 10px 0;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
}
.register-mobile-group .register-link img {
    width: 150px;
}
.register-mobile-group .register-link {
    display: inline-block;
    padding: 0 5px;
}
.register-mobile-group .register-link a {
	text-decoration: none;
}
.register-mobile-group .register-link h3 {
    margin: 10px 0;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
}
.joinus .doctor-review{
	text-align: center;
	padding-bottom: 100px;
}
.joinus .doctor-review h1{
	margin-bottom: 20px;
}
.joinus .doctor-review img{
	min-width: 100px;
    max-width: 100%;
    padding: 20px;
}