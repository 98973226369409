.py-10 {padding:10px 0;}
.px-10 {padding:0 10px;}
.mt-15 { margin-top: 15px;}
.pb-20 {padding-bottom: 20px;}
.m-0 { margin: 0; }

#review .review-background { 
  background-color: #f9f9f9; 
  margin-bottom: 10px;
}
#review .review-thankyou-background { background-color: #6ea1e0; }
#review .grey-color { color: #b6b6b6; }
#review .green-color { color: #5bb657; }
#review .nav-time {
  font-size: 16px;
  color: #3589b7; 
  margin: 15px 0;
}
#review .bold {font-weight: 400; }
#review .bolder {font-weight: 600; }
#review .title {font-size: 24px;}
#review .review-border{
  border:1px solid #ececec;
  padding: 15px 10px;
  margin-bottom: 20px;
  font-size: 16px;
}
#review .rating-heart .glyphicon-heart-empty{
  color: #b5b5b5;
}
#review .rating-heart,
#review .rating-heart-readonly {
  color: #e3546a;
  padding: 5px 0;
  font-size: 20px;
}
#review .review-thankyou{
  margin:20px;
  padding:20px;
  background: white;
  border-radius: 20px;
}
#review .review_doctor_profile{
  margin: 0 20px 0 0;
}
#review .review_doctor_name{
  font-size: 20px;
  margin-top: 15px;
}
#review .review_addr{
  font-size: 14px;
  color: #5e5e5e;
}
#review .review_doctor_specialty{
  font-size: 16px;
}
#review .review_clinic_name{
  margin-top: 10px;
}
#review .review_feedback_form{
  font-size: 16px;
}
#review .review_feedback_form .control-label{
  text-align: left;
  font-weight: bold;
}
#review .profile-picture-mobile{
  display: inline-block;
  width: 75px;
  height: 75px;
  background-position: center !important;
  background-position-y: 0 !important;
  border-radius: 150px;
  background-size: cover !important;
}
#review .profile-picture{
  display: inline-block;
  width: 150px;
  height: 150px;
  background-position: center !important;
  background-position-y: 0 !important;
  border-radius: 150px;
  background-size: cover !important;
}